import styled from "@emotion/styled";
import React from "react";

interface CoverViewProps {
    children: React.ReactNode;
    image: React.ReactNode;
    sp_slide: string;
}

CoverView2.defaultProps = {
    sp_slide: "50%",
}

export default function CoverView2(props: CoverViewProps) {
    return (
        <Container slide={props.sp_slide}>
            <div className="background-image">{props.image}</div>
            <div className="inner">
                {props.children}
            </div>
        </Container>
    );
}

const Container = styled.div<{ slide: string }>`

  height: 100%;
  min-height: max( 580px, 100vh);
  width: 100%;
  position: relative;
  
  .top-cover{
    min-height: max( 580px, 100vh);
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: inherit;
    background-color: #000;

    & > .gatsby-image-wrapper {

      width: 100%;
      height: 100%;
    
      & > picture {
        img {
          height: 100%;
          min-height: 800px;
          object-fit: cover;
        }
      }
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .inner {
    min-height: max( 580px, 100vh);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1ch;
  }

  @media (max-width: 768px) {
    background-position-x: ${(props) => props.slide};
  }
`;
