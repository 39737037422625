import styled from "@emotion/styled";
import React from "react";
import Layout from "../components/layout";
import ProductTechnology from "../components/pages/product/ProductTechnology";
import PageTitle from "../components/common/PageTitle";
import NormalText from "../components/common/NormalText";
import {StaticImage} from "gatsby-plugin-image";
import BoarderSectionTitle from "../components/common/BoarderSectionTitle";
import Masonry from "react-masonry-css";
import CoverView2 from "../components/common/CoverView2";

export default function Product() {

    const breakpointColumnsObj = {
        default: 3,
        1000: 2,
        320: 1,
    };

    return (
        <Layout>
            <CoverView2 image={
                <StaticImage
                    src="../assets/img/studio/cover.jpg"
                    alt="studio top"
                    objectFit="cover"
                    layout="fullWidth"
                    quality={100}
                    className="top-cover"
                />
            }>
                <StaticImage
                    src={"../assets/img/studio/studio_title.png"}
                    alt="どうやったらよいものが作れるか"
                    placeholder="tracedSVG"
                />
            </CoverView2>
            <Container>
                <div className="page-title">
                    <PageTitle title="STUDIO"/>
                </div>

                <div className="cover-image">
                    <p className="bl-philosophy-text is-hide">
                        ヒトとは何者か。私は人の肉体という具体的な対象だけでなく、人間の発生そのもの、社会の起源、概念にも興味がある。ヒトはいつから ‘ヒト’ になったのか。ヒトたらしめるものは何なのか。人類学者レヴィ=ストロースは
                        言う「人類の思考は、最初から完成されていた。人類が人類となったときに作られた脳の構造を、私たち現代人もいまだに使って思考している」私は、さらなるヒトの美しさを求めて、実験的に模索しながら学問のよう
                        に追求していく。デザイン、生地、生産体制、販売、感触、環境、、、。あらゆる段階で試行錯誤を繰り返す。 その先にある、独自に美しさを纏い、個とりどりの風景を目指して。関わる全ての人を心地よく、存在を開放できる世界になることを強く願っています。
                    </p>
                    <StaticImage
                        src={"../assets/img/studio/human_pc.png"}
                        className="philosophy-image"
                        alt="Pholosophy"
                        quality={100}
                    />
                </div>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid atelier_images"
                    columnClassName="my-masonry-grid_column"
                >
                    <StaticImage src="../assets/img/studio/atelier/atelier0.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier1.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier8.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier3.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier4.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier5.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier6.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier7.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier9.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier2.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier10.jpg" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/atelier/atelier11.jpg" alt="atelier" quality={100}/>
                </Masonry>

                <div className="bl-vision">

                    <h2>ニットと学問</h2>
                    <StaticImage
                        src="../assets/img/studio/knitology_sketch.png"
                        alt="knitology_sketch"
                        objectFit="cover"
                        className="vision-image"
                        quality={100}
                    />
                    <div className="knitology_word">
                        <StaticImage
                            src="../assets/img/studio/KNIT+LOGY.png"
                            alt="knitology_sketch"
                            objectFit="contain"
                            quality={100}
                        />
                    </div>
                    <p className="knitology_desc is-hide">
                        デザイン、技術、生産体制、販売、<br className="hidePC"/>環境、肌触り、触感、人間工学<br/>
                        あらゆる角度から<br className="hidePC"/>ニットを通して考えていく。<br/>
                    </p>
                </div>
                <div className="bl-technology">
                    <BoarderSectionTitle title="Technology"></BoarderSectionTitle>
                    <div className="bl-technology_desc">
                        <NormalText>
                            KNITOLOGYはアトリエに編機を所有しています。<br/>
                            クオリティを追求できる環境で、こだわり抜いた一着を仕上げます。
                        </NormalText>
                    </div>
                    <div className="bl-technology_cover">
                        <StaticImage
                            src="../assets/img/studio/machine.jpg"
                            alt="machine"
                            className="image_pic"
                            quality={100}
                        />
                    </div>
                    <ProductTechnology></ProductTechnology>
                </div>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid atelier_images"
                    columnClassName="my-masonry-grid_column"
                >
                    <StaticImage src="../assets/img/studio/space/space0.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space1.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space2.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space4.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space5.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space3.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space11.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space9.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space8.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space7.png" alt="atelier" quality={100}/>
                    <StaticImage src="../assets/img/studio/space/space10.png" alt="atelier" quality={100}/>
                </Masonry>
            </Container>
        </Layout>
    );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #000;
  margin-top: 100px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }

  .cover-image {

    margin-top: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1ch;

    @media (max-width: 768px) {
      margin-top: 60px;
    }

    p {
      padding: 30px;
      //font-family: YuMincho;
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 0.08em;
      line-height: 37px;
      text-align: left;
      color: #fff;
    }

    .philosophy-image {
      margin: 20px;
    }
  }

  .bl-vision {

    margin-top: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      margin-top: 0px;
    }

    h2 {
      margin: 40px auto 100px;
      //font-family: YuMincho;
      font-weight: 500;
      font-size: 144px;
      letter-spacing: 0.08em;
      line-height: 87px;
      text-align: left;
      color: #fff;

      @media (max-width: 768px) {
        margin: 40px auto 40px;
        font-size: 13vw;
        line-height: 44px;
      }
    }

    .vision-image {
      width: 100%;
    }

    .knitology_word {
      margin-top: 120px;
      padding: 0 20px;

      @media (max-width: 768px) {
        margin-top: 60px;
      }
    }

    
    .knitology_desc {
      margin-top: 20px;


      height: 419px;
      //font-family: YuMincho;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.08em;
      line-height: 38px;
      text-align: center;
      color: #fff;

      @media (max-width: 768px) {
        //font-size: 44px;
        font-size: 4.5vw;
        line-height: 9vw;
      }
    }
  }

  .bl-technology {
    padding: 100px 0 160px;

    @media (max-width: 768px) {
      padding: 0px;
    }

    &_desc {
      margin-top: 60px;
      padding: 0 24px;
      
      
    }

    &_cover {
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
  }

  .my-masonry-grid {
    display: flex;
    margin: 0px auto;
    padding: 120px 30px 120px 0;
    max-width: 1320px;

    @media (max-width: 768px) {
      padding: 120px 0px 120px 0;
    }

  }

  .my-masonry-grid_column {
    /* gutter size */
    padding-top: 30px;
    padding-left: 30px;
    background-clip: padding-box;

    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .my-masonry-grid_column > div {
    background: grey;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-bottom: 40px;
    }
  }
`;
