import styled from "@emotion/styled";
import React from "react";

interface BoarderSectionTitleProps {
    title: string;
}

export default function BoarderSectionTitle(props: BoarderSectionTitleProps) {
    return (
        <SectionTitleWrap>
            <h3 className="title">
                {props.title}
            </h3>
        </SectionTitleWrap>
    );
}

const SectionTitleWrap = styled.div`
  
  margin: 60px auto;
  width: calc(100% - 60px);
  max-width: 1440px;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }

  .title{
    display: flex;  /* 横並びにする */
    align-items: center;  /* 垂直方向に中央 */
    text-align: center;  /* 文字のセンタリング(複数行になったとき用) */
    
    //font-family: Didot;
    font-weight: normal;
    font-size: 40px;
    letter-spacing: 0.1em;
    line-height: 93px;
    text-align: center;
    color: #9B8750;
    
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 24px;
    }
  }
  
  /* 共通部分はまとめる */
  .title::before,
  .title::after{
    content:"";
    height: 6px;
    flex-grow: 1;  /* 線の伸び率 */
    border-bottom: 1px double #707070;
    border-top: 1px double #707070;
  }

  /* 左側の線 */
  .title::before{
    margin-right: 36px;  /* 線と文字の間のスペース */
    padding-left: 36px;  /* 線の長さが、最低でも15px以上になるように余白をとる */

    @media (max-width: 768px) {
      margin-right: 20px;
      padding-left: 20px;
    }
  }

  /* 右側の線 */
  .title::after{
    margin-left: 36px;  /* 線と文字の間のスペース */
    padding-right: 36px;  /* 線の長さが、最低でも15px以上になるように余白をとる */
    @media (max-width: 768px) {
      margin-left: 20px;
      padding-right: 20px;
    }
  }
  
`;
