import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";

export default function ProductTechnology() {
    return (
        <TechnologyWrap>

            <article className="bl_tech_contents layout_left">
                <div className="bl_tech_contents_image">
                    <StaticImage src="../../../assets/img/product/tech_env.jpg"
                                 alt="work wear"
                                 className="image_pic"
                                 quality={100}
                    />
                </div>
                <div className="bl_tech_contents_message">
                    <h4 className="tech_title">こだわり抜くためのアトリエ環境</h4>
                    <p className="tech_text">
                        デザイナー自らCAD（ニット機を作動させるためのプログラム作り）を操り、そのCADデータを自社で所有するニット機を使い編み、日々新製品の開発をしています。これは、ニット製品の開発は編み機での試作が非常に重要であり、どのぐらい機械上の限界値まで調整をかけられるか、が最終製品のクオリティを左右させるからです。素材やニット技術の模索に可能な限り時間をかけ、妥協のない物づくりを目指し、デザイン、素材開発、編みサンプル、量産データの製作まで全てアトリエで行うことで「実験的に模索し、ニットを学問のように研究していく」ことを実現しています。
                    </p>
                </div>
            </article>

            <article className="bl_tech_contents">
                <div className="bl_tech_contents_image">
                    <StaticImage src="../../../assets/img/product/tech_tex.jpg"
                                 alt="work wear"
                                 className="image_pic"
                                 quality={100}
                    />
                </div>
                <div className="bl_tech_contents_message">
                    <h4 className="tech_title">基本を実直に「美しい目面」</h4>
                    <p className="tech_text">
                        KNITOLOGYの編み立てる生地で大切にしているのが、「目面」という言葉です。目面とは、目の揃った表面生地の状態を表しています。この目面が美しく整っていることは、生地表面を滑らかにし、高級感と、素材の強さをもたらしてくれます。そしてSTOLL社の機械は目面がとても美しく編むことができます。凝った技術を使っているわけではなく、とてもシンプルで針の間隔や機械の微調整を繰り返した基本的な技術を徹底的に追求しています。
                    </p>
                </div>
            </article>

            <article className="bl_tech_contents layout_left">
                <div className="bl_tech_contents_image">
                    <StaticImage src="../../../assets/img/product/tech_form.jpg"
                                 alt="work wear"
                                 className="image_pic"
                                 quality={100}
                    />
                </div>
                <div className="bl_tech_contents_message">
                    <h4 className="tech_title">見えない部位へのこだわり</h4>
                    <p className="tech_text">
                        美しいフォルを作るために、従来のニット製品に比べてパーツが多い構造になっています。生地をハサミでカットする裁断を行わず、１パーツ１パーツ時間をかけて形通りに編み上げる「成形」という技術を全面的に駆使しています。この「成形」は通常の編み時間の数倍時間のかかる繊細な編み作業になります。端に耳となる部分を作りロックミシンなどの処置を省くことで、縫い代を極限まで細く仕上げることができます。シンプルな処理により、組み立てた時にスッキリと美しいシームラインが現れ、着用した時ごわつかず、表面も美しく保つことができるのです。ほんの小さなことだけど、妥協を許さず時間をかけて丁寧に作成しています。
                    </p>
                </div>
            </article>

        </TechnologyWrap>
    );
}

const TechnologyWrap = styled.div`

  max-width: 1024px;
  margin: 0 auto;
  padding: 0 24px;

  .bl_tech_contents {
    display: flex;
    align-items: start;
    margin-top: 140px;

    @media (max-width: 768px) {
      display: block;
    }

    &_image {
      flex: 1 1 500px;
      
      @media (max-width: 768px) {
        width: 100%;
        .image_pic{
          width: 100%;
        }
      }
    }

    &_message {
      flex: 1 1 500px;
      padding-left: 46px;
      @media (max-width: 768px) {
        padding-left: 0px;
        margin-top: 40px;
      }

      .tech_title {
        //font-family: YuMincho;
        font-weight: 500;
        font-size: 23px;
        letter-spacing: 0.1em;
        line-height: 40px;
        text-align: left;
        color: #fff;

        @media (max-width: 768px) {
          font-size: 5vw;
        }
        
      }

      .tech_text {
        margin-top: 24px;
        //font-family: YuMincho;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 28px;
        text-align: left;
        color: #fff;
      }
    }

    &.layout_left {

      .bl_tech_contents_image {
        order: 2;
        @media (max-width: 768px) {
          order: 1;
        }
      }

      .bl_tech_contents_message {
        order: 1;
        padding-left: 0px;
        padding-right: 46px;
        @media (max-width: 768px) {
          order: 2;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }


  h3 {
    //font-family: Didot;
    font-size: 40px;
    letter-spacing: 4px;
    line-height: 93px;
    color: ${(props) => props.theme.grey};
  }
`;
