import styled from "@emotion/styled";
import React from "react";

interface SectionTitleProps {
    title: string;
}

export default function PageTitle(props: SectionTitleProps) {
    return (
        <Container>
            <h1>
                {props.title}
            </h1>
        </Container>
    );
}

const Container = styled.div`
  
  margin-top: 100px;

  h1 {
    
    //font-family: Didot;
    font-weight: normal;
    font-size: 58px;
    font-size: min(10vw, 58px);
    
    
    letter-spacing: 0.09em;
    line-height: 26px;
    text-align: center;
    color: #9b8750;
    
    
  }
`;
