import styled from "@emotion/styled";
import React from "react";

interface Props {
    children?: React.ReactNode
}

export default function NormalText(props: Props) {
    return (
        <Text>
            {props.children}
        </Text>
    );
}

const Text = styled.p`
  //font-family: YuMincho;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.1em;
  line-height: 32px;
  text-align: center;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 3vw;
  }
`;
